/* eslint-disable */
/* eslint-disable no-unused-vars */
import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilAudioDescription, cil3d } from '@coreui/icons'

const _nav =  [
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Dashboard',
  //   to: '/dashboard',
  //   icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon"/>,
  //   badge: {
  //     color: 'info',
  //     // text: 'NEW',
  //   }
  // },
  // {
  //   _tag: 'CSidebarNavDivider'
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['User'],
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'SignUp/Login',
  //   route: '/pages',
  //   icon: 'cil-star',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Login',
  //       to: '/login',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Register',
  //       to: '/register',
  //     }
  //   ],
  // },  {
    {
      _tag: 'CSidebarNavTitle',
      _children: ['Home']
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      icon: 'cil-speedometer'
    },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Components']
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Administration',
    route: '/administration',
    icon: 'cil-shield-alt',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'DB Admin',
        to: '/dbadmin',
      },
     // {
       // _tag: 'CSidebarNavItem',
      //  name: 'Currency Converter',
      //  to: '/currencyconverter',
    //  }
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Tools',
    route: '/tools',
    icon: <CIcon content={cil3d} customClasses="c-sidebar-nav-icon icons_class"/>,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'DemoPage',
        to: '/demopage',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'UploadFiles',
      //   to: '/demopage',
      // },
      {
        _tag: 'CSidebarNavItem',
        name: 'Special Units',
        to: '/specialunits'
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Reports',
        to: '/reports'
      }
    ],
  },
  {
    _tag: 'CSidebarNavDivider'
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Avhb'],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Avhb',
    route: '/avhb',
    icon: <CIcon content={cilAudioDescription} customClasses="c-sidebar-nav-icon icons_class"/>,
    _children: [
      {                         
        _tag: 'CSidebarNavItem',
        name: 'Configurations  (Website)',
        to: '/configurations',
      },
      {                         
        _tag: 'CSidebarNavItem',
        name: 'Configurations (Mobile)',
        to: '/configurationsmobile',
      },
    //  {
     //   _tag: 'CSidebarNavItem',
     //   name: 'Administration',
     //   to: '/administration',
    //  }
    ],
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Registered Clients'],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Registered Clients',
    to: '/registeredClients',
    icon: 'cil-people'
  }
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Account'],
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Logout',
  //   to: '/',
  //   icon: 'cil-lock-locked',
  //   attributes:{
  //     onClick: ()=>{
  //       // submit();
  //       alert("hi!");
  //      }
  //   }
  // },
]

export default _nav
