import React from 'react'
import { CFooter } from '@coreui/react'

const TheFooter = ({customClass}) => {
  return (
    <CFooter fixed={false} className={`d-flex justify-content-center ${customClass}`}>
      <div className="mx-4 mx-sm-0 sm-text">
        <a href="https://advergic.com" target="_blank" rel="noopener noreferrer" className='color_8c8c8c text_decoration_none'>Advergic © 2024 | </a>
        <a href="https://advergic.com/privacy-policy/" target="_blank" rel="noreferrer noopener" className='color_8c8c8c text_decoration_none'> Privacy Policy</a> © 
        <a href="https://advergic.com/terms-of-service/" target="_blank" rel="noreferrer noopener" className='color_8c8c8c text_decoration_none'> Terms of Service</a>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)
