/* eslint-disable */
/* eslint-disable no-unused-vars */
import React from 'react'
import axios from 'axios'
import logos from "../assets/logo/Advergic-Logo.png";
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'
import { cilAccountLogout } from '@coreui/icons';

// sidebar nav config
import navigation from './_nav'
import Config from "../config";
import CIcon from '@coreui/icons-react'
import Cookies from 'universal-cookie'

const cookies = new Cookies();
const TheSidebar = () => {

  const baseUri = Config.API_BASE_URI;
  const dispatch = useDispatch()
  const show = useSelector(state => state.sidebarShow)

  const logout = () => {  
    axios.get(baseUri + "exit")
      .then(response => {
        window.location.href="/";
        cookies.remove("data",{path:"/"})
        cookies.remove("token",{path:"/"})
        cookies.remove("data.email",{path:"/"})
        })
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <CSidebar
    show={show}
    onShowChange={(val) => dispatch({ type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand className="sidebar-logo d-md-down-none c_sidebar_brand" to="/dashboard">
        <CIcon
          className="c-sidebar-brand-full"
          name="logo-negative"
          src={logos}
          height={35}
        />
      </CSidebarBrand>

      <CSidebarNav>
        {/* <img className='advergic_logo' src='https://advergic.com/wp-content/themes/av-v2/img/advergic-logo.svg'></img> */}
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />

        <CSidebarNavTitle>Account</CSidebarNavTitle>
        <CSidebarNavItem className="c-sidebar-nav-item">
          <div className="c-sidebar-nav-link c-active cursor_pointer">
            <CIcon content={cilAccountLogout} className="c-sidebar-nav-icon" />
            <span onClick={logout}>Logout</span>
          </div>
        </CSidebarNavItem>

      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
