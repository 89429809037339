// import React from 'react'
/* eslint-disable */
const Config = {
    // API_BASE_URI: "http://localhost:8080/",
    // FILES_BASE_URI: "http://localhost:4040/",
    // CREATIVE_BASE_URI: "http://localhost:6060/",
    // FLASK_BASE_URI: "http://localhost:5000/",
    // FRONTEND_URI: "http://localhost:3000/",
    // LOCATION_BASE_URI: "http://localhost:8081/",
    API_BASE_URI: "https://backend.advergic.com/",
    FILES_BASE_URI: "https://int.advergic.com/",
    CREATIVE_BASE_URI: "https://avads.live/",
    LOCATION_BASE_URI: "https://an.avads.live/",
    FILES_BASE_URI: "https://int.advergic.com/",
    FRONTEND_URI: "https://tools.advergic.com/"
};
export default Config;