import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { TheLayout } from './containers';
import './scss/style.scss';
import Protected from './Protected';

const loading = (
// <div className="loading-loader">
// <img className="img-loader" src="http://karamalegkos.com/demonew/newdesign/cogs.gif" /> 
// </div>
// <div class="sk-chasing-dots">
// <div class="sk-child sk-dot1"></div>
// <div class="sk-child sk-dot2"></div>
// </div> 
// <div class="spinner-grow" role="status">
// <span class="sr-only">Loading...</span> 
// </div>   
  <div className="pt-3 text-center">
  <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ForgetPassword = React.lazy(() => import('./views/pages/forgetPassword/fp'));
const SetPassword = React.lazy(() => import('./views/pages/setPassword/setPassword'));

class App extends Component {
  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/forgetpassword" name="forget password" render={props => <ForgetPassword {...props}/>} />
              <Route exact path="/setpassword/*" name="set password" render={props => <SetPassword {...props}/>} />
              <Route path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/dashboard" name="Dashboard">
              <Protected render={props => <TheLayout {...props}/>}/>               
              </Route> 
              <Route path="/" name="The Layout">
                <Protected render={props => <TheLayout {...props}/>}/>               
              </Route> 
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
